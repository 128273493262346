import Http from "./http";

export default class BaseService {
  instance: any;
  constructor() {
    this.instance = new BaseService();
  }

  static request() {
    return new Http().instance;
  }
}
