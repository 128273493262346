export const API_URL = 'https://api.lerigol.com.br'
//export const API_URL = 'http://localhost:3000';


/* Error messages */
export const SERVER_ERROR_MESSAGE = 'Tivemos um problema, tente novamente mais tarde';
export const UNAUTHORIZED_ERROR_MESSAGE = 'Usuário não autorizado';
export const INVALID_CREDENTIALS_ERROR_MESSAGE = 'Credenciais incorretas';
export const NOT_FOUND_ERROR_MESSAGE = 'Registro não encontrado';
export const INVALID_TOKEN = 'Token inválido';
export const AFFILIATION_EXISTS = 'Você já é afiliado deste produto';


/* Success messages */
export const SUCCESS_PIX_KEY_UPDATED = 'Chave pix atualizada com sucesso';
export const SUCCESS_WITHDRAW = 'Saque efetuado com sucesso!';


/* User password messages */
export const INCORRECT_CURRENT_PASSWORD = 'Senha antiga incorreta';
export const INACTIVE_USER = 'Usuário em etapa de ativação. Aguarde a finalização ou entre em contato com o suporte para ativar o seu usuário'


