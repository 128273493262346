import { AxiosError } from "axios";
import BaseService from "./base.service";
import { CustomError } from "./errors/CustomError";
import { InfluencerDto } from "../dtos/influencer.dto";
import { NOT_FOUND_ERROR_MESSAGE, SERVER_ERROR_MESSAGE } from "../constants";

export default class InfluencerService extends BaseService {
    static async findInfluencerData(
        influencerLink: string
    ): Promise<InfluencerDto> {
        try {
            const result = await this.request().get(
                `/influencers?link=${influencerLink}`
            );

            return result.data;
        } catch (error) {
            
            if (error instanceof AxiosError) {
                if (error.response?.status === 404) {
                    throw new CustomError(NOT_FOUND_ERROR_MESSAGE, 404);
                }

                throw new CustomError(SERVER_ERROR_MESSAGE);
            }

            throw new CustomError(SERVER_ERROR_MESSAGE);
        }
    }
}
