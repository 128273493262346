export class CustomError extends Error {
 
  
    constructor(message: string, public statusCode:number = 400) {
      super(message);
  

      Object.setPrototypeOf(this, CustomError.prototype);
    }
  
    getErrorMessage() {
      return 'Something went wrong: ' + this.message;
    }
  }
  