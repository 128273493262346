<template>
    <span class="block h-px w-full bg-gray-200 my-3"></span>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>