<template>
    <div class="flex items-center justify-center">
        <div>
            <p class="text-center text-[100px] font-poppins mt-5 text-gray-700 mt-24">404</p>

            <img src="@/assets/not-found.svg" class="w-64" />
            <p class="text-center text-lg font-poppins mt-8 text-gray-700">Influencer não encontrado</p>
        </div>

    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped></style>