<template>
  <div class="bg-gray-600 w-full text-center text-white mt-8 py-8">
    <p class="text-xs ">
      Lerigol Tecnologia LTDA CNPJ: 43.700.379/0001-00 Rua teste teste, 30 -
      Sala 1 - Centro - CEP 08975-000 - Maringá - PR
    </p>
    <p class="text-xs">© 2023 Lerigol - Todos os direitos reservados</p>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
