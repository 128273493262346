import { API_URL } from "@/constants";
import axios, { AxiosInstance, AxiosResponse } from "axios";


export default class Http {
  public instance: AxiosInstance;

  constructor() {

    this.instance = axios.create({
      baseURL: API_URL,
      timeout: 10000
    });

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      async (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}
