import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Vue3Lottie from "vue3-lottie";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const options = {};

const app = createApp(App);

app.use(Toast, options);
app.use(router);
app.use(Vue3Lottie, { name: "Vue3Lottie" });

app.mount("#app");
